import './App.css'
import { Box, Divider } from '@mui/material'
import admindBusinessPng from './images/admind-business.png'
import confirmation1 from './images/confirmation1.png'
import confirmation2 from './images/confirmation2.png'


const Header = () => {
    return <Box id={'header'} sx={{
        backgroundColor: '#31245C',
        height: '10vh',
        textAlign: 'center'
    }}>
        <img style={{ height: '2rem',  marginTop: '1rem' }} src={admindBusinessPng} />
    </Box>
}



const Confirmation = () => {

    return <Box id={'confirmation'}>
        <Header />
        <Box sx={{ width: '100%', height: '90vh', display: 'flex', textAlign: 'center', flexDirection: 'column', width: '100%' }} id={'body'}>

            <Box sx={{ marginTop: '2rem' }}>
                <h1 style={{ fontSize: '2.2rem', color: '#601C7E' }}>SCOPRI DI PIÙ</h1>
            </Box>
            <Box>
                <Box>
                    <img style={{ height: '15rem' }} src={confirmation2} onClick={() => window.location.href = 'https://qr.link/JSBuAp'} />
                </Box>
            </Box>
            <Box>
                <Box>
                    <img style={{ height: '15rem' }} src={confirmation1} onClick={() => window.location.href = 'https://business.admind.ai'} />
                </Box>
            </Box>
            <Divider />
            <Box sx={{ paddingBottom: '2rem' }}>
                <Box sx={{ marginTop: '1.5rem', }}>
                    <Box sx={{ marginBottom: '0.8rem', fontSize: "1rem" }}>E-MAIL</Box>
                    <a style={{ fontSize: '1.2rem', fontWeight: 'bold', textDecoration: 'none', color: 'black' }} href="mailto:business@admind.ai">business@admind.ai</a>

                </Box>
                <Box sx={{ marginTop: '1.5rem', }}>
                    <Box sx={{ marginBottom: '0.8rem', fontSize: "1rem" }} >CELLULARE</Box>
                    <a style={{ fontSize: '1.2rem', fontWeight: 'bold', textDecoration: 'none', color: 'black' }} href="tel:+393510308319">+39 351 0308319</a>


                </Box>

            </Box>

        </Box>
    </Box>

}

export default Confirmation
