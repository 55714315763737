import React from 'react'
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom"
import './index.css'


// Components
import NotFound from './404'
import App from './App'
import Confirmation from './Confirmation'

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(

  <React.StrictMode>

    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <App  />} />
        <Route exact path="/confirmation" render={() => <Confirmation />} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>


  </React.StrictMode>
)

