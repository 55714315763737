import { useState } from 'react'
import './App.css'
import robotChampPng from './images/robot-champ.png'
import admindBusinessPng from './images/admind-business.png'
import { Box, TextField, Button, Checkbox } from '@mui/material'
import app from './firebase-app'
import validator from 'validator'
import { getDatabase, push, ref } from "firebase/database"

const database = getDatabase(app)

const generateVmf = (code) => {
    const codeUpper = code.toUpperCase()
    const codeFormatted1 = Number(codeUpper.split('V')[1])
    const codeFormatted2 = Number(codeUpper.split('H')[1])
    const codeFormatted3 = Number(codeUpper.split('P')[1])
    const codeFormatted4 = Number(codeUpper.split('F')[1])

    if (codeFormatted1 >= 1 && codeFormatted1 <= 100) {
        return {
            title: 'Un avatar con AI gratuito!',
            link: 'https://calendly.com/aimindmkt/avatar-gratuito-con-ai-admind',
        }
    } else if (codeFormatted2 >= 101 && codeFormatted2 <= 200) {
        return {
            title: 'Una chatbot con AI gratuita!',
            link: 'https://calendly.com/aimindmkt/regalo-chatbot-gratuito-con-ai_admind',
        }
    } else if (codeFormatted3 >= 201 && codeFormatted3 <= 300) {
        return {
            title: 'Un mockup gratuito per una piattaforma AI!',
            link: 'https://calendly.com/aimindmkt/regalo-preventivo-con-mockup-gratuito-con-ai_admind',
        }
    } else if (codeFormatted4 >= 301 && codeFormatted4 <= 400) {
        return {
            title: 'Una piattaforma AI che ricerca e rielabora notizie per il tuo sito!',
            link: 'https://calendly.com/aimindmkt/regalo-piattaforma-genera-articoli-con-ai_a-clone',
        }
    } else {
        return null
    }

}
const Header = () => {
    return <Box id={'header'} sx={{
        backgroundColor: '#31245C',
        height: '14vh',
        textAlign: 'center'
    }}>
        <img style={{ height: '3rem', marginTop: '1rem' }} src={admindBusinessPng} />
    </Box>
}

const FirstView = ({ setView, setCodeObject }) => {
    const [currentCode, setCurrentCode] = useState(null)
    const [email, setEmail] = useState(null)
    const [error, setError] = useState(null)
    const [privacyChecked, setPrivacyChecked] = useState(false)

    const getCode = () => {
        const codeObject = generateVmf(currentCode)

        if (!codeObject) {
            setError('Per favore, inserisci un codice valido.')
        }else if(!email || !validator.isEmail(email)){
            setError('Per favore, inserisci una email valida.')
        } else {
            push(ref(database, 'emails/'), {
                email
              })

            setCodeObject(codeObject)
            setView(2)
        }
    }
    return <Box id={'first-view'}>
        <Header />
        <Box sx={{ height: '86vh', display: 'flex', textAlign: 'center', flexDirection: 'column' }} id={'body'}>
            <Box sx={{ marginTop: '2rem' }}>
                <img style={{ height: '10rem' }} src={robotChampPng} />
            </Box>
            <Box sx={{ padding: '0 4.2rem' }}>
                <h1 style={{ fontSize: '2.2rem', color: '#601C7E' }}>BEN FATTO, HAI VINTO!</h1>
            </Box>
            <Box sx={{ padding: '0 2.5rem' }}>
                <span style={{ fontSize: '1.2rem', color: '#707070' }}>Inserisci il codice che hai grattato
                    qui sotto e scopri cosa hai vinto</span>
            </Box>
            <Box sx={{ marginTop: '1.5rem', }}>
                <TextField  onChange={(event) => {
                    setEmail(event.target.value)
                    setError(null)
                }} variant="outlined" sx={{ width: '80%' }} placeholder='Inserisci email' />


            </Box>
            <Box sx={{ marginTop: '1.5rem', }}>
                <TextField onChange={(event) => {
                    setCurrentCode(event.target.value)
                    setError(null)
                }} variant="outlined" sx={{ width: '80%' }} placeholder='Inserisci codice' />


            </Box>
            {error && <span style={{ color: 'red', fontSize: '1rem', marginTop: '0.5rem' }}>{error}</span>}
            <Box sx={{ marginTop: '1rem', fontSize:'0.6rem', paddingLeft:'1.8rem' ,
                textAlign:'left',
            '@media (min-width:1200px)': {
                paddingLeft:'0' ,
                textAlign:'center',
                fontSize:'0.8rem',
            }
            }}>
            <Checkbox onChange={() => setPrivacyChecked(!privacyChecked)} checked={privacyChecked} /><span>Ho letto e accetto la <a target='_blank' href='https://www.admind.ai/en/privacy-policy/'>privacy</a> e di ricevere offerte commerciali</span>

            </Box>
            <Box sx={{ paddingBottom:'1.2rem', marginTop: '1rem', opacity: !currentCode && '0.8' }}>
                <Button onClick={() => getCode(currentCode)} disabled={!currentCode || !privacyChecked || !email} sx={{
                    backgroundColor: '#FFA500', color: 'white', width: '60%', padding: '1rem', borderRadius: '12px', fontSize: '1rem', fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#FFA500',
                        opacity: '0.8'
                    },
                }}>
                    SCOPRI IL TUO PREMIO
                </Button>

            </Box>
            
        </Box>
    </Box>
}

const SecondView = ({ codeObject }) => {

    return <Box id={'first-view'}>
        <Header />
        <Box sx={{ height: '86vh', display: 'flex', textAlign: 'center', flexDirection: 'column' }} id={'body'}>
            <Box sx={{ padding: '0 2.5rem', marginTop: '3rem' }}>
                <span style={{ fontSize: '1.4rem' }}>Ecco che cosa hai vinto:</span>
            </Box>
            <Box sx={{ padding: '0 3rem' }}>
                <h1 style={{ fontSize: '2.2rem', color: '#601C7E' }}>{codeObject.title}</h1>
            </Box>
            <Box sx={{ padding: '0 2rem' }}>
                <span style={{ fontSize: '1.2rem', color: '#707070' }}>Non preoccuparti, se non hai idee ti aiutiamo
                    noi a trovare il progetto perfetto per il tuo business!</span>
            </Box>

            <Box sx={{ marginTop: '3rem' }}>
                <Button onClick={() => window.location.href = codeObject.link} sx={{ backgroundColor: '#FFA500', color: 'white', width: '60%', padding: '1rem', borderRadius: '12px', fontSize: '1rem', fontWeight: 'bold',
                '&:hover': {
                    backgroundColor: '#FFA500',
                    opacity: '0.8'
                },
            }}>
                    AVVIA IL PROGETTO
                </Button>

            </Box>
            <Box sx={{ marginTop: '2rem', padding: '0 2rem' }}>
                <span style={{ fontSize: '1.2rem', lineHeight:1.4 }}>Clicca e prenota una call con il nostro
                    team per riscattare il tuo premio e
                    avviare il tuo progetto!</span>
            </Box>
        </Box>
    </Box>
}

const App = () => {
    const [view, setView] = useState(1)
    const [codeObject, setCodeObject] = useState(null)

    return <Box>
        {view === 1 && <FirstView setView={setView} setCodeObject={setCodeObject} />}
        {view === 2 && <SecondView codeObject={codeObject} />}
    </Box>
}

export default App
